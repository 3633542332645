import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Deload Week`}</em></p>
    <p><strong parentName="p">{`Gymnastic Day`}</strong></p>
    <p>{`Strict Pullups 3×12`}</p>
    <p>{`Strict Parallel Bar Dips 3×12`}</p>
    <p>{`(scale to dips off bench)`}</p>
    <p>{`then, 20:00 amrap of:`}</p>
    <p>{`21-18-15-12-9-6-3 each`}</p>
    <p>{`Calorie Assault Bike`}</p>
    <p>{`Pushups`}</p>
    <p>{`Ring Rows`}</p>
    <p>{`V Ups`}</p>
    <p>{`*`}<em parentName="p">{`if you complete the 3 set start back at 21 each until time runs out.`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      